import IconTag from "components/Icon/IconTag";
import { toDDMMYYYYHHMM } from "lib/date";
import { useIsDesktop } from "lib/device";
import { useRouter } from "next/router";

interface FProps {
  title?: string;
  description?: string;
  useBorder?: boolean;
  withLink?: boolean;
  withTag?: boolean;
  tag?: TagProps;
  isUsingButtonViewAll?: boolean;
}

export type TagType = "Kursus" | "Promo" | "Transaksi" | "Info" | "Tenant";

interface TagProps {
  code?: string;
  type?: TagType;
  isActive?: boolean;
}

const FieldWithBorder: React.FC<FProps> = ({
  title,
  description,
  useBorder = true,
  withLink = false,
  withTag = false,
  isUsingButtonViewAll,
  ...props
}) => {
  const router = useRouter();
  const isNotifPage = router.pathname == "/notification";
  let classesTitle = withLink
    ? "font-medium text-c1 text-neutral-50"
    : "font-light text-c1 !text-neutral-60 dark:text-[#FFFFFF]";

  const renderLink = () => {
    return `${description} <a href="/" class="text-orange-50">Lanjutkan disini.</a>`;
  };

  const isDesktop = useIsDesktop();

  return (
    <div
      className={
        withTag
          ? props.tag.isActive
            ? "bg-purple-05"
            : isUsingButtonViewAll
            ? "bg-purple-05 dark:bg-dark-20"
            : "bg-white"
          : "gap-16 my-16 grid"
      }
    >
      {withTag && (
        <div className="flex gap-8 items-center p-16">
          <div className="grid gap-8">
            <div className="flex gap-8 items-center">
              <IconTag {...props.tag} />
              <div
                className={
                  (isDesktop ? "text-b1 " : "text-c1 ") +
                  "font-black " +
                  (props.tag.isActive ? "text-neutral-80" : "text-neutral-60")
                }
              >
                {props.tag.code !== "tenant" ? props.tag.type : "Informasi"}
              </div>
              <div className="font-black text-c1 text-neutral-60 flex gap-8 items-center">
                <div className="w-6 h-6 bg-neutral-30 rounded-full"></div>

                {toDDMMYYYYHHMM(title, "long", false, true)}
              </div>

              {/* <div className="font-black text-c1 text-neutral-60 flex gap-8 items-center">
                19.00
              </div> */}
            </div>

            <div
              className={
                (isDesktop ? "text-b1 " : "text-c1 ") +
                "text-purple-80 min-h-[16px] " +
                (props.tag.isActive ? "font-medium" : "font-light")
              }
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </div>

          {props.tag.isActive ? (
            <div className="w-30 flex justify-end m-auto mr-0">
              <div className="w-8 h-8 rounded-full bg-purple-50"></div>
            </div>
          ) : (
            <div className="w-30 flex justify-end m-auto mr-0"></div>
          )}
        </div>
      )}

      {withLink && (
        <>
          <div className={classesTitle}>{title}</div>
          <div
            className={
              "font-black text-purple-80 min-h-[16px] " +
              (isDesktop ? " text-b1" : " text-c1")
            }
            dangerouslySetInnerHTML={{ __html: renderLink() }}
          ></div>
        </>
      )}

      {!withLink && !withTag && (
        <>
          <div className={classesTitle}>{title}</div>

          <div
            className={
              "font-medium text-purple-80 min-h-[16px] " +
              (isDesktop ? " text-b1" : " text-c1")
            }
          >
            {description}
          </div>
        </>
      )}

      <div className="h-[0.5px] w-full border-b border-neutral20_to_dark30"></div>
    </div>
  );
};

export default FieldWithBorder;
